import React from 'react';

import classNames from 'classnames';
import Image from 'next/image';

import Link from '../../../components/elements/Link';
import HeroPanelStyles from './HeroPanel.module.scss';

const HeroPanel = () => {
  return (
    <div
      className={classNames(HeroPanelStyles.panelWrapper, {
        [HeroPanelStyles['topPanel']]: 'topPanel',
      })}
    >
      <section className={HeroPanelStyles.heroContainer}>
        <video
          className={HeroPanelStyles.heroVideo}
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          aria-label="Video player"
          aria-hidden="true"
          poster="/images/miscellaneous/still-ocean-placeholder.webp"
        >
          <source
            src="https://website-admin.corp.milltechfx.com/assets/f5c053b4-a443-4df2-af96-3aecb9bc0d6d"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        {/* <!-- Transparent overlay image --> */}
        <Image
          className={HeroPanelStyles.heroOverlay}
          src="/images/miscellaneous/hero-video-shadow.png"
          alt=""
          fill
          // sizes="100vw"
          aria-hidden="true"
          decoding="async"
          priority={true}
        />

        {/* <!-- Text content --> */}
        <div className={HeroPanelStyles.heroContent}>
          <h1>FX & Cash Management</h1>
          <h2>Automated. Integrated. Connected</h2>
          <p>
            We provide advanced FX Hedging and Cash Investment solution to increase market access,
            reduce costs and automate manual workflows.
          </p>
        </div>

        <div className={HeroPanelStyles.buttons}>
          <Link to={'/contact/'} title={'Speak to us'} variant="mint" style={{}}>
            Speak to us
          </Link>
          <Link
            to={'/tca-form/'}
            title={'Request a TCA'}
            variant="ghost"
            style={{ marginLeft: 24 }}
          >
            Request your TCA
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HeroPanel;
