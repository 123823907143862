import * as React from 'react';

import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';

import HeroPanel from '../components/common/HeroPanel';
import Layout from '../components/common/Layout';
import { Solutions } from '../components/common/Solutions';
import StatsPanel from '../components/common/StatsPanel';
import Strip from '../components/common/Strip';
import { MobileContextProvider } from '../helpers';
import { withTranslation } from '../translation';

const DynamicAutomate = dynamic(() => import('../components/common/Automate'), {
  ssr: false,
});
const DynamicConnected = dynamic(() => import('../components/common/Connected'), {
  ssr: false,
});
const DynamicIntegrate = dynamic(() => import('../components/common/Integrate'), {
  ssr: false,
});
const DynamicAwards = dynamic(() => import('../components/elements/Awards'), {
  ssr: false,
});
const DynamicClientsContactPanel = dynamic(
  () => import('../components/common/ContactPanel/ClientsContactPanel'),
  {
    ssr: false,
  }
);

const IndexPage = () => {
  const { t } = useTranslation('home');
  const homeData = t('home', { returnObjects: true }) as any;
  const { SEO, awards, getInTouch } = homeData;

  return (
    <MobileContextProvider>
      <Layout SEO={SEO}>
        <Strip variant="blue" style={{ padding: 0 }} fullWidth>
          <HeroPanel />
        </Strip>
        <Strip variant="white" style={{ padding: 0 }}>
          <StatsPanel />
        </Strip>
        <Strip variant="blue" style={{ padding: 0 }}>
          <Solutions />
        </Strip>
        {/* BELOW FOLD */}
        <Strip variant="white" style={{ padding: 0 }}>
          <DynamicAutomate />
        </Strip>
        <Strip variant="blue" style={{ padding: 0 }}>
          <DynamicConnected />
        </Strip>
        <Strip variant="white" style={{ padding: 0 }}>
          <DynamicIntegrate />
        </Strip>
        <Strip variant="grey" style={{ padding: 0 }}>
          <DynamicAwards awards={awards} />
        </Strip>
        <Strip variant="white" style={{ padding: 0 }}>
          <DynamicClientsContactPanel data={getInTouch} />
        </Strip>
      </Layout>
    </MobileContextProvider>
  );
};

export default IndexPage;

export const getStaticProps = withTranslation(async (context) => {
  return {
    props: {},
  };
});
