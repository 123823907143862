import React from 'react';

import Image from 'next/image';

import Link from '../../../components/elements/Link';
import StatsPanelStyles from './StatsPanel.module.scss';

const STATS = [
  { label: '$500bn+', desc: 'Annual trading volume', image: '/images/miscellaneous/cash.png' },
  { label: '250+', desc: 'Client Entities', image: '/images/miscellaneous/people.png' },
  { label: '$30bn', desc: 'Client Programmes', image: '/images/miscellaneous/chart.png' },
  { label: '20+', desc: 'Liquidity providers', image: '/images/miscellaneous/bank.png' },
  { label: '30+', desc: 'Years of innovation', image: '/images/miscellaneous/roots.png' },
  { label: '1', desc: 'Platform', image: '/images/miscellaneous/Milltech platform.png' },
];

const StatsPanel = () => {
  return (
    <section className={StatsPanelStyles.stats}>
      <div className={StatsPanelStyles.wrapper}>
        {STATS.map((stat, index) => (
          <div key={index} className={StatsPanelStyles.card}>
            <Image
              src={stat.image}
              alt={stat.label}
              width="130"
              height="0"
              sizes="65px"
              className={StatsPanelStyles.img}
            />
            <div className={StatsPanelStyles.label}>{stat.label}</div>
            <span className={StatsPanelStyles.desc}>{stat.desc}</span>
          </div>
        ))}
      </div>
      <div className={StatsPanelStyles.cta}>
        <Link to={'/company/about-us/'} title={'About us'} variant="mint" style={{ width: 160 }}>
          About us
        </Link>
      </div>
    </section>
  );
};

export default StatsPanel;
