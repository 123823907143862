import { Flex } from 'antd';
import Image from 'next/image';

import { Link } from '../../elements';
import Styles from './Solutions.module.scss';

const SOLUTIONS = [
  {
    title: 'FX Management',
    description:
      'Multi-bank ISDA set-up and outsourced execution via a tech-enabled and highly scalable operational platform to significantly reduce hedging costs.',
    image: '/images/miscellaneous/FX-management.png',
  },
  {
    title: 'Cash Management',
    description:
      'Automate cash investments via our integrated marketplace of money-market funds for enhanced returns and reduced counterparty risk.',
    image: '/images/miscellaneous/cash-management.png',
  },
  {
    title: 'Co-Pilot',
    description:
      'Next-generation risk advisory and calculation services designed to help efficiently manage FX risk, optimize cash deployment, and improve decision-making.',
    image: '/images/miscellaneous/co-pilot.png',
  },
];

export default function Solutions() {
  return (
    <Flex className={Styles.solutionsWrapper} align="center" vertical>
      <h2>Our Solutions</h2>
      <p>
        Access our Risk Management and Liquidity solutions via a single platform purpose-built to
        deliver best execution at scale.
      </p>
      <Flex wrap align="stretch" className={Styles.solutions} justify="center">
        {SOLUTIONS.map((solution, index) => (
          <div
            className={Styles.solution}
            key={solution.title}
            style={{ justifyContent: 'space-between' }}
          >
            <Image alt={solution.title} src={solution.image} width={60} height={60} />
            <h3>{solution.title}</h3>
            <div className={Styles.divider} />
            <p>{solution.description}</p>

            <Link
              to={'/company/about-us/'}
              title={'Find out more'}
              variant="mint"
              style={{ width: 140 }}
            >
              Find out more
            </Link>
          </div>
        ))}
      </Flex>
    </Flex>
  );
}
